<script>
import {useStore} from "vuex";
import {computed, onBeforeMount, ref, watch} from "@vue/runtime-core";
import CustomCheckbox from "@/components/Forms/Fields/CustomCheckbox.vue";
import {useRoute} from "vue-router";
import Preloader from "@/App.vue";
import {reactive} from "@vue/reactivity";
import MiniPreloader from "@/components/Technical/MiniPreloader.vue";

export default {
  name: "ReferenceProfilesModal",
  components: {MiniPreloader, Preloader, CustomCheckbox},
  setup() {
    const store = useStore();
    const route = useRoute();
    const isLoading = ref(false);
    const allReferenceProfiles = computed(
        () => store.state.analytics.referenceProfiles
    );
    const attachedReferenceProfiles = computed(
        () => store.state.group.attachedReferenceProfiles
    );

    const showedProfiles = computed(() => {
      let data = allReferenceProfiles.value.map((profile) => {
        let attached = attachedReferenceProfiles.value ?
            attachedReferenceProfiles.value.find((attachedProfile) =>
            attachedProfile.id === profile.id)
            : false;

        return {
          id: profile.id,
          title: profile.title,
          attached: !!attached,
        }
      });

      return data.sort((a, b) => b.attached - a.attached);
    });

    const notificationStatus = computed(() => store.state.group.referenceProfileNotification);

    const notificationSettings = reactive(
        {
          sendNotification: 0,
        }
    );

    onBeforeMount(() => {
      isLoading.value = true;
      store.dispatch("group/getNotificationSettings", route.params.id);
      store.dispatch("analytics/getReferenceProfiles");
      store.dispatch("group/getAttachedToGroupReferenceProfiles", route.params.id)
          .then(() => isLoading.value = false)
          .catch(() => isLoading.value = false);
    });

    const changeNotificationSetting = () => {
      if (notificationSettings.sendNotification !== notificationStatus.value.notification_on) {
        store.dispatch("group/updateNotificationSettings", {
          group_id: route.params.id,
          sendNotification: notificationSettings.sendNotification,
        });
      }
    };

    const attachProfile = (referenceProfileId) => {
      store.dispatch("group/attachReferenceProfile", {
        group_id: route.params.id,
        profile_id: referenceProfileId
      });
    }

    const detachProfile = (referenceProfileId) => {
      store.dispatch("group/detachReferenceProfile", {
        group_id: route.params.id,
        profile_id: referenceProfileId,
      });
    }

    watch(() => notificationSettings.sendNotification, () => {
      changeNotificationSetting();
    });

    watch(() => notificationStatus.value, () => {
      notificationSettings.sendNotification = notificationStatus.value.notification_on;
    });

    return {
      showedProfiles,
      isLoading,
      notificationSettings,
      detachProfile,
      attachProfile,
    }
  },
}
</script>

<template>
  <div class="overlay" @click="$emit('close')">
    <div class="reference_modal" @click.stop>
      <div class="modal_wrapper">
        <div class="card-header card-header-divider">
          <div>
            <p>Управление Эталонными профилями</p>
          </div>
          <div class="close" @click="$emit('close')" role="button">
            <img class="close_img" src="@/assets/images/icons/close.svg" alt="Крестик">
          </div>
        </div>
        <div v-if="!isLoading && showedProfiles && showedProfiles.length" class="main_wrapper">
          <ul class="profile_list">
            <li v-for="(profile) in showedProfiles" :key="profile.id">
              <div class="profile_wrapper">
                <div>
                  <p>{{ profile.title }}</p>
                </div>
                <div class="button_wrapper">
                  <button v-if="profile.attached" @click="detachProfile(profile.id)" class="btn btn-dark">
                    Открепить
                  </button>
                  <button v-else @click="attachProfile(profile.id)" class="btn btn-accent">
                    Прикрепить
                  </button>
                </div>
              </div>
            </li>
          </ul>
          <div class="checkbox_wrapper">
            <custom-checkbox
                :label="'Получать уведомления, если найден пользователь, соответсвующий эталону'"
                v-model="notificationSettings.sendNotification"
                :checked="notificationSettings.sendNotification"
                :fromValue=true
                :value="1"
            />
          </div>
        </div>
        <div v-if="!isLoading && showedProfiles && !showedProfiles.length" class="no_results">
            <p>
              Нет доступных Эталонных профилей
            </p>
        </div>
      </div>
      <div v-if="isLoading" class="preloader">
        <mini-preloader/>
      </div>
    </div>
  </div>
</template>

<style scoped lang="scss">

.reference_modal {
  display: flex;
  flex-direction: column;
  position: absolute;
  background-color: white;
  min-width: 700px;
  min-height: 500px;
  padding: 20px 40px;
  top: 10%;
  z-index: 20;
}

.overlay {
  z-index: 5;
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  display: flex;
  justify-content: center;
  background-color: #000000da;
  overflow: auto;
}

@media only screen and (max-height: 570px) {
  .reference_modal {
    margin-top: 0;
  }
}

.profile_wrapper {
  padding: 25px;
  display: flex;
  column-gap: 20px;
  justify-content: space-between;
  align-items: center;
  flex-wrap: wrap;
}

.close {
  position: absolute;
  top: 55px;
  right: 49px;
  cursor: pointer;
}

.close_img {
  width: 20px;
}

.card-header {
  margin-bottom: 20px;
}

.main_wrapper {
  display: flex;
  flex-direction: column;
  row-gap: 50px;
  padding-left: 40px;
}

.checkbox_wrapper {
  margin-top: auto;
}

.profile_list {
  display: flex;
  flex-direction: column;
}

li {
  border-bottom: 1px solid var(--cream-color);
}

li:nth-child(even) {
  background-color: #f5f5f5;
}

.preloader {
  display: flex;
  margin: auto;
}

.no_results {
  margin-top: 30%;
  text-align: center;
}

@media (max-width: 768px) {
  .main_wrapper {
    padding-left: 0;
  }

  .profile_wrapper {
    flex-direction: column;
    row-gap: 15px;
  }

  .button_wrapper {
    margin-left: auto;
  }

  .reference_modal {
    left: 0;
    width: 100%;
    min-width: 0;
    padding-bottom: 35px;
  }
}
</style>