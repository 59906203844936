<template>
  <div class="modal-overlay" @click="$emit('close-modal')">
    <div class="modal" @click.stop>
      <div class="modal__box">
        <div class="close" @click="$emit('close-modal')">
          <img class="close-img" src="@/assets/images/icons/close.svg" alt="">
        </div>
        <p class="modal__title">Выдать доступ:</p>
        <p class="modal__text">Вы отметили <span>{{ checkedList.length + " " + morph(checkedList.length) }}</span>. Выберите продукты, к которым вы хотите выдать доступ.</p>
      </div>
      <div class="modal__table" v-if="productOptions">

        <base-table
            v-if="shownData.length"
            :columns="quotaFields"
            :rows="shownData"
            :checkedList="checkedProducts"
            :rowSelect="onRowSelected"
            @sort="(data) => {
              sortValue = data.sortValue;
              sortDirection = data.direction;
            }"
        />
      </div>
      <div v-else class="table-preloader">
        <preloader/>
      </div>
      <div class="btn-tools">
        <div class="btn-box-item">
          <custom-checkbox
              v-if="appenedQuotas"
              name="automatic"
              :disabled="reportType === 'empty'"
              v-model="automaticGiving"
              label="Выдать отчет автоматически после прохождения"
          />
          <button v-if="appenedQuotas" class="btn btn-accent" :disabled="!isChecked" @click="quotaAppend">
            Выслать
            приглашение
          </button>
          <button v-else class="btn btn-accent" :disabled="!isChecked" @click="quotaRemove">забрать</button>
        </div>
        <div class="btn-box-item">
          <a :href="siteUrl" target="_blank" class="btn clear-btn">+ заказать новый продукт</a>
          <button class="popper-info btn btn-accent" @click="openModal">Заказать дополнительно</button>
        </div>
      </div>
      <pop-up-form title="Оформить заказ"
                   v-if="showModal"
                   @closeModal="showModal = false"/>
    </div>
  </div>
</template>

<script>
import {computed, onBeforeMount, ref, toRef, watch} from "@vue/runtime-core";
import {useStore} from "vuex";
import {useRoute} from "vue-router";
import Preloader from "@/components/Technical/Preloader.vue";
import PopUpForm from "@/components/Popups/PopUpForm.vue";
import CustomCheckbox from "@/components/Forms/Fields/CustomCheckbox.vue";
import BaseTable from "@/components/Tables/BaseTable.vue";
import sortData from "@/mixins/sortData";

export default {
  name: "quotas-modal",
  components: {BaseTable, CustomCheckbox, PopUpForm, Preloader},
  props: {
    checkedList: {
      type: Array,
      required: true,
    },
    appenedQuotas: {
      type: Boolean,
      required: true
    },
    productOptions: {
      type: Array,
      required: true,
    }
  },
  setup(props, {emit}) {
    const store = useStore(),
        route = useRoute(),
        checkedList = toRef(props, "checkedList"),
        isChecked = ref(true),
        countOnPage = ref(10),
        siteUrl = process.env.VUE_APP_SITE;
    const reportType = route.params.product;
    const automaticGiving = ref(false);
    const checkedProducts = ref([]);

    const rowData = computed(() => {
      return props.productOptions.map((quotas) => {
        return {
          id: quotas.id,
          name: quotas.name,
          countFree: quotas.countFree,
          countIssued: quotas.countIssued,
        }
      })
    })

    const onFirstDataRendered = () => {
      rowData.value.forEach((row) => {
        if (row.id === route.params.product) {
          checkedProducts.value.push(row.id);
          isChecked.value = true;
        }
      });

      rowData.value = rowData.value.sort((el) => checkedProducts.value.includes(el.id) ? -1 : 1)
    }

    const onRowSelected = (id, selectAll) => {
      const isInclude = checkedProducts.value.includes(id);
      if (id && isInclude) {

        if (!selectAll) {
          checkedProducts.value = checkedProducts.value.filter(
              (currentId) => currentId !== id
          );
        }
      } else {
        checkedProducts.value.push(id);
      }

      isChecked.value = !!checkedProducts.value.length;
    };

    const quotaAppend = () => {
      store
          .dispatch("users/quotaAppend", {
            products: checkedProducts.value,
            users: checkedList.value,
            allow_view_report: automaticGiving.value
          })
          .then(() => {
            store.dispatch("company/getUserQuotas")
            emit('closeModal')
          })
    }
    const quotaRemove = () => {
      store
          .dispatch("users/quotaRemove", {
            products: checkedProducts.value,
            users: checkedList.value
          })
          .then(() => {
            store.dispatch("company/getUserQuotas")
            emit('closeModal')
          })
    }

    const quotaFields = [
      {
        field: "check",
        headerName: "",
        checkAll: true,
      },
      {
        field: "name",
        headerName: "Продукты",
        sortable: true,
      },
      {
        field: "countFree",
        headerName: "Доступно мест",
        sortable: true,
        cellRenderer: "FreeQuotaAction",
      }
    ];

    const sortValue = ref('');
    const sortDirection = ref('');

    const shownData = computed(() => {

      let data = rowData.value ?? [];

      if (data.length) {

        if (sortValue.value) {
          data = sortData(data, sortValue.value, sortDirection.value);
        }
      }

      return data;
    });

    const morph = (int, array) => {
      return (array = array || ['человек', 'человека', 'человек']) && array[(int % 100 > 4 && int % 100 < 20) ? 2 : [2, 0, 1, 1, 1, 2][(int % 10 < 5) ? int % 10 : 5]];
    }

    const showModal = ref(false)
    const openModal = () => {
      showModal.value = !showModal.value
    }

    onBeforeMount(() => {
      onFirstDataRendered();
    })

    watch(
        () => [route.params.id, route.params.product],
        () => {
          onFirstDataRendered();
        }
    )

    return {
      rowData,
      countOnPage,
      siteUrl,
      onRowSelected,
      quotaAppend,
      quotaRemove,
      close,
      isChecked,
      morph,
      showModal,
      openModal,
      reportType,
      automaticGiving,

      shownData,
      sortDirection,
      sortValue,
      quotaFields,
      checkedProducts
    }
  }
}

</script>
<style scoped lang="scss">

.modal-overlay {
  z-index: 5;
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  display: flex;
  justify-content: center;
  background-color: #000000da;
  overflow: auto;
}

@media only screen and (max-height: 570px) {
  .modal {
    margin-top: 0 !important;
  }
}

.modal {
  display: block !important;
  overflow-y: initial !important;
  position: relative;
  background-color: white;
  height: 715px;
  width: 900px;
  margin-top: 10%;
  padding: 20px 40px;

  &__box {
    text-align: left;
  }

  &__title {
    font-weight: 400;
    font-size: 36px;
    line-height: 37px;
    @media (max-width: 715px) {
      font-size: 24px;
    }
  }

  &__text {
    padding-top: 10px;
    font-weight: 400;
    font-size: 16px;
    line-height: 28px;
    @media (max-width: 715px) {
      font-size: 14px;
      line-height: 18px;
    }
  }

  &__table {
    height: 500px;
    overflow-y: auto;
    margin: auto;
  }

  &__btn {
    padding-top: 30px;
  }
}

.close {
  position: absolute;
  right: 49px;
  cursor: pointer;
}

.close-img {
  width: 25px;
}

.btn-tools {
  display: flex;
  justify-content: space-between;
  padding-top: 10px;
  @media (max-width: 715px) {
    flex-direction: column;
  }
}

.btn-box-item {
  display: flex;
  flex-direction: column;
  justify-content: center;
}

@media (max-width: 715px) {
  .modal {
    margin-top: 0;
    height: 100%;
  }
}
</style>