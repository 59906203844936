<template>
  <div class="filters">
    <div class="primary_filters">
      <custom-input
          label="Имя"
          :name="'name'"
          :inputWidth="100"
          cleanable
          v-model="searchParams.name"
      />
      <custom-input
          :name="'email'"
          label="Email"
          :inputWidth="100"
          cleanable
          v-model="searchParams.email"
      />
      <custom-select-2
          v-model="searchParams.referenceProfileId"
          name="reference"
          label="Эталонный профиль"
          :options="referenceProfileOptions"
      />
      <custom-select-2
          v-model="searchParams.employeePosition"
          v-if="isTest"
          name="reference"
          label="Должность сотрудника"
          :options="positionOptions"
      />
      <custom-select-2
          v-model="searchParams.courseName"
          v-if="!isTest"
          name="reference"
          label="Наименование курса"
          :options="courseNamesOptions"
      />
      <custom-select-2
          v-model="searchParams.passageStatusTest"
          v-if="isTest"
          name="reference"
          label="Статус прохождения"
          :options="passageStatusesTestOptions"
      />
      <custom-select-2
          v-model="searchParams.passageStatusCourse"
          v-if="!isTest"
          name="reference"
          label="Статус прохождения"
          :options="passageStatusesCourceOptions"
      />
    </div>
    <div class="secondary_filters">
      <custom-checkbox
          v-if="group !== 'all'"
          v-model="searchParams.onlyGroup"
          :checked="!!searchParams.onlyGroup"
          label="Не состоит в других группах"
          :from-value="true"
          :value="1"
      />
      <div v-if="isTest">
        <custom-checkbox
            :disabled="!!searchParams.noActiveTest"
            v-model="searchParams.activeTest"
            :checked="searchParams.activeTest"
            label="Имеет активный тест"
            :from-value="true"
            :value="1"
        />
        <custom-checkbox
            :disabled="!!searchParams.activeTest"
            v-model="searchParams.noActiveTest"
            :checked="searchParams.noActiveTest"
            label="Не имеет активных тестов"
            :from-value="true"
            :value="1"
        />
      </div>
      <div v-else>
        <custom-checkbox
            :disabled="!!searchParams.noActiveCourse"
            v-model="searchParams.activeCourse"
            :checked="searchParams.activeCourse"
            label="Имеет активный курс"
            :from-value="true"
            :value="1"
        />
        <custom-checkbox
            :disabled="!!searchParams.activeCourse"
            v-model="searchParams.noActiveCourse"
            :checked="searchParams.noActiveCourse"
            label="Не имеет активных курсов"
            :from-value="true"
            :value="1"
        />
      </div>
    </div>
    <div class="control_button">
      <button @click="clearSearch" class="btn btn-w clear-btn">Очистить</button>
      <button @click="searchRequest" class="btn btn-accent">Фильтровать</button>
    </div>
  </div>
</template>

<script>
import CustomInput from "@/components/Forms/Fields/CustomInput.vue";
import CustomSelect2 from "@/components/Forms/Fields/CustomSelect2.vue";
import {computed, onBeforeMount, reactive, ref, watch} from "@vue/runtime-core";
import {useStore} from "vuex";
import {useRoute} from "vue-router";
import CustomCheckbox from "@/components/Forms/Fields/CustomCheckbox.vue";

export default {
  name: "FilterBlock",
  components: {CustomCheckbox, CustomSelect2, CustomInput},
  props: {
    product: String,
    group: String,
    isTest: Boolean,
  },
  setup(props, {emit}) {
    const store = useStore();
    const route = useRoute();
    const allReferenceProfiles = computed(
        () => store.state.analytics.referenceProfiles
    );
    const groupProfiles = computed(() => store.state.group.attachedReferenceProfiles);

    const referenceProfileOptions = computed(() => {
      const profiles = (route.params.id && route.params.id !== 'all') ? groupProfiles.value : allReferenceProfiles.value;
      return [{
        id: 0,
        text: 'Не выбрано'
      }].concat(profiles.map((profile) => {
        return {
          id: profile.id,
          text: profile.title,
        }
      }));
    });

    let courseNamesOptions = ref([]),
        positionOptions = ref([]);
    const positionList = computed(() => store.state.test.positionList);
    const userQuotas = computed(() => store.state.company.userQuotas);
    let searchParams = reactive({
          name: "",
          email: "",
          referenceProfileId: "0",
          onlyGroup: 0,
          activeTest: 0,
          noActiveTest: 0,
          activeCourse: 0,
          noActiveCourse: 0,
          employeePosition: "Не выбрано",
          courseName: "Не выбрано",
          passageStatusTest: 'Не выбрано',
          passageStatusCourse: "Не выбрано",
        }),
        profile = computed(() => store.state.profile.profile),
        passageStatusesTestOptions = computed(() =>[
          'Не выбрано',
          'Новые',
          'Начатые',
          'В процессе',
          'Не назначенные',
          'Выдан другой',
          'Завершенные'
        ]),
        passageStatusesCourceOptions = computed(() =>
            [
              "Не выбрано",
              "Новые",
              "В процессе",
              "Завершенные",
              "Не назначенные",
            ]
        )

    const passageStatusMap = {
      "Не выбрано": null,
      "Новые": "new",
      "Начатые": "instruction",
      "В процессе": "in_progress",
      "Завершенные": "finished",
      "Не назначенные": "unassigned",
      "Выдан другой": "other"
    };

    const courceStatusMap = {
      "Не выбрано": null,
      "Новые": "new",
      "В процессе": "in_progress",
      "Завершенные": "finished",
      "Не назначенные": "unassigned",
    };

    function prepareSearchParams() {
      const paramsCopy = { ...searchParams };
      paramsCopy.passageStatusTest = passageStatusMap[searchParams.passageStatusTest] || null;
      paramsCopy.passageStatusCourse = courceStatusMap[searchParams.passageStatusCourse] || null;
      return paramsCopy;
    }

    const searchRequest = () => {
      emit('loading');
      if (searchParams.activeCourse && searchParams.noActiveCourse) {
        searchParams.noActiveCourse = 0;
      }

      if (searchParams.activeTest && searchParams.noActiveTest) {
        searchParams.noActiveTest = 0;
      }

      store
          .dispatch("group/getProductGroup", {
            product: props.product,
            id: props.group,
            search_data: prepareSearchParams(),
          }).then(() => emit('loaded'));
    };

    const clearSearch = () => {
      searchParams.referenceProfileId = "0";
      searchParams.name = "";
      searchParams.email = "";
      searchParams.onlyGroup = 0;
      searchParams.activeTest = 0;
      searchParams.noActiveTest = 0;
      searchParams.activeCourse = 0;
      searchParams.noActiveCourse = 0;
      searchParams.passageStatusTest = { label: "Не выбрано", value: null };
      searchParams.passageStatusCourse = 'Не выбрано';
      searchRequest();
    };

    onBeforeMount(() => {
      if (route.params.id && route.params.id !== 'all') {
        store.dispatch("group/getAttachedToGroupReferenceProfiles", route.params.id);
      } else {
        store.dispatch("analytics/getReferenceProfiles");
      }
      store.dispatch("company/getUserQuotas");
      store.dispatch("test/getPositionList").then(() => {
        positionOptions.value = positionList.value.map(position => position.text);
        positionOptions.value.unshift('Не выбрано');
      });
    });

    watch(userQuotas, () => {
      courseNamesOptions.value = userQuotas.value.course_passings.total.map(course => course.name);
      courseNamesOptions.value.unshift('Не выбрано');
    });

    return {
      positionOptions,
      positionList,
      courseNamesOptions,
      passageStatusesTestOptions,
      passageStatusesCourceOptions,
      searchParams,
      clearSearch,
      searchRequest,
      referenceProfileOptions,
    }
  }
}
</script>

<style scoped lang="scss">
.filters {
  position: sticky;
  padding: 5px 0;
  top: 0;
  background-color: #ffffff;
  z-index: 2;
}

.secondary_filters {
  display: flex;
  column-gap: 10px;
  align-items: center;
  justify-content: space-between;
}

.control_button {
  margin-top: 20px;
  display: flex;
  column-gap: 20px;
  justify-content: space-between;
}

@media (max-width: 800px) {
  .secondary_filters {
    margin: 10px 0;
    flex-direction: column;
    align-items: flex-start;
  }
}
</style>
