export const getTestStatus = (staff) => {
    const statusesMap = {
        'questionnaire': 'Тест не пройден',
        'instruction': 'Тест не пройден',
        'expired': 'Тест не пройден',
        'new': 'Тест не пройден',
        'in_progress': 'В процессе прохождения',
        'finished': 'Тест завершен',
    }

    if (staff.test_passing) {
        return statusesMap[staff.test_passing.status] ?? 'Тест не пройден';
    } else if (staff.active_test_passing) {
        const reportNames = {
            "standard": "стандартным",
            "full": "полным"
        };
        const name = reportNames[staff.active_test_passing.report_type] || '';
        return `Выдано тестирование с ${name} отчетом`
    } else {
        return "Тест не выдан";
    }
};
